
import Joi from "joi";


const customMessages = {
  "string.base": "{{#label}} doit être une chaîne de caractères",
  "string.uri": "{{#label}} doit être une URL valide",
  "string.empty": "{#label} ne doit pas être vide",
  "array.min": "{#label} doit comporter au minimum {{#limit}} elément(s)",
  "array.max": "{#label} doit comporter au plus {{#limit}} elément(s)",
  "array.length": "{#label} doit comporter au minimum {{#limit}} elément(s)",
  "string.email": "Veuillez fournir une adresse email valide pour {{#label}}",
  "string.required": "{{#label}} est obligatoire",
  "string.length":
    "{{#label}} doit contenir contient {{#limit}} de caractère(s)",
  "string.min": "{{#label}} doit comporter au minimum {{#limit}} caractère(s)",
  "string.max": "{{#label}} doit comporter au plus {{#limit}} caractère(s)",
  "any.required": "{{#label}} est obligatoire",
  "string.isoDate": "{{#label}} doit être une date valide au format ISO",
  "string.valid": "{{#label}} n'a pas une valeur valide",
  "boolean.base": "{{#label}} doit être un boolean",
  "number.base": "{{#label}} doit être un nombre",
  "object.base": "{{#label}} doit être un objet",
  "string.pattern.base": "{{#label}} est invalide",
  "string.pattern.name":
    "La chaîne doit contenir au moins 3 caractères, y compris des chiffres.",
  "string.pattern.invert.base": "{{#label}} est invalide",
  "string.pattern.invert.name": "{{#label}} est invalide",
  "string.trim": "{{#label}} est invalide",
};

const returnError = (response) => {
  const { error } = response;
  if (error) {
    const firstError = error.details[0];
    const fieldName = firstError.path[0];
    const errorMessage = firstError.message;

    return {
      valid: false,
      error: true,
      errorData: { field: fieldName, message: errorMessage },
    };
  }
  return { valid: true };
};

export const BillCenterValidator = (data) => {
  console.log(data)
  const schema = Joi.object({
    customer_id: Joi.number().required().label("Identifiant client"),
    billing_center_name: Joi.string().required().label("Nom du centre de facturation"),
    address: Joi.string().required().label("Adresse"),
    address2: Joi.string().allow(null, '').label("Complément d'adresse"),
    city: Joi.string().required().label("Ville"),
    postal_code: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required().label("Code postal"),
    country: Joi.string().required().label("Pays"),
    telephone1: Joi.string().required().label("Téléphone 1"),
    telephone2: Joi.string().allow(null, '').label("Téléphone secondaire"),

    email_facturation: Joi.string().email({ tlds: { allow: false } }).required().label("Email de facturation"),
    type: Joi.string().required().label("Type"),
    responsable_comptable: Joi.string().allow(null, '').label("Responsable comptable"),
    intra_vatnumber: Joi.string().required().label("TVA intracommunautaire"),
    // numero_siren: Joi.string().required().label("Numéro SIREN"),
    // numero_siret: Joi.string().required().label("Numéro SIRET"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });
  return returnError(schema.validate(data));
};

export const ClientValidator = (data) => {
  console.log(data)
  const schema = Joi.object({
    fullname: Joi.string().required().label("Nom complet"),
    address: Joi.string().required().label("Adresse de facturation"),
    address2: Joi.string().allow(null, '').label("Complément d'adresse"),
    city: Joi.string().required().label("Ville"),
    postal_code: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required().label("Code postal"),
    country: Joi.string().required().label("Pays"),
    telephone: Joi.string().required().label("Téléphone"),
    invoice_mail: Joi.string().email({ tlds: { allow: false } }).required().label("Email de facturation"),
    type: Joi.string().required().label("Type"),
    intra_vatnumber: Joi.string().required().label("TVA intracommunautaire"),
    // siren: Joi.string().required().label("Numéro SIREN"),
    // numero_siret: Joi.string().required().label("Numéro SIRET"),
    // numero_eori: Joi.string().required().label("Numéro EORI"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });
  return returnError(schema.validate(data));
};

export const UserDataValidator = (data) => {
  const schema = Joi.object({
    name: Joi.string().required().label("Nom"),
    address1: Joi.string().required().label("Adresse"),
    city: Joi.string().required().label("Ville"),
    postal_code: Joi.string()
      .pattern(/^[a-zA-Z0-9]{4,}$/)
      .min(4)
      .required().label("Code postal"),
    country: Joi.string().required().label("Pays"),
    telephone1: Joi.string().required().label("Téléphone 1"),
    email: Joi.string().email({ tlds: { allow: false } }).required().label("Email"),
    interface: Joi.string().required().label("Interface"),
    customer_id: Joi.any().required().label("Identifiant client"),
    bill_center_id: Joi.any().required().label("Identifiant centre de facturation"),
    entreprise_physique: Joi.string().required().label("Nom Direction / Service / Département physique"),
  }).messages(customMessages)
    .unknown(true) // Autoriser les champs supplémentaires
    .messages({
      "object.unknown": " Vous aviez ajouté un champ supplémentaire {{#label}}", // Message pour les champs supplémentaires
    });
  return returnError(schema.validate(data));


};